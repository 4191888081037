<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-month-picker
            placeholder="计划年月"
            v-model="form.date"
            :allowClear="true"
            style="width: 150px"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-select v-model="form.type" placeholder="类型" style="width: 150px">
            <a-select-option
              v-for="item in project_plan_type"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="部门"
            show-search
            :filter-option="$selectFilterOption"
            v-model="form.deptId"
            style="width: 150px"
          >
            <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">
              {{
              item.uniqueName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-select placeholder="处理状态" v-model="form.confirmStatus" style="width: 150px">
            <a-select-option
              v-for="item in project_plan_confirm_status"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-select placeholder="完成状态" v-model="form.completionStatus" style="width: 150px">
            <a-select-option
              v-for="item in project_plan_completion_status"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button @click="query" type="primary">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="部门" data-index="deptName"></a-table-column>
        <a-table-column title="计划类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="project_plan_type" :dictValue="text.type" />
          </template>
        </a-table-column>

        <a-table-column title="计划年月" align="center">
          <template slot-scope="text">
            <span v-if="text.type === 'projectPlanYear'">{{ text.year + '年' }}</span>
            <span v-else>{{ text.year + '年' + text.month + '月' }}</span>
          </template>
        </a-table-column>

        <a-table-column title="填报时间" data-index="fillingTime" align="center"></a-table-column>

        <a-table-column title="处理状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="project_plan_confirm_status" :dictValue="text.confirmStatus" />
          </template>
        </a-table-column>
        <a-table-column title="完成状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="project_plan_completion_status"
              :dictValue="text.completionStatus"
            />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="right" width="180px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/confirm') && text.confirmStatus === 'project_plan_confirm_status_to_be_confirmed'"
                @click.prevent="confirm(text)"
              >确认</a>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit') && text.confirmStatus === 'project_plan_confirm_status_return'"
                @click.prevent="callback(text)"
              >重新提交</a>

              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="seeDetail(text)"
              >详情</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import organization from "@/mixins/organization";
import { mapGetters } from "vuex";

import request from "@/api/request";

function fetchList(data) {
  return request({
    url: "/project-service/projectPlan/list",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark, organization],

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    project_plan_type() {
      return this.findDataDict("project_plan_type");
    },
    project_plan_confirm_status() {
      return this.findDataDict("project_plan_confirm_status");
    },
    project_plan_completion_status() {
      return this.findDataDict("project_plan_completion_status");
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
        date: undefined,
        year: this.form.date ? this.form.date.format("YYYY") : undefined,
        month: this.form.date ? this.form.date.format("M") : undefined
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    confirm(text) {
      this.$router.push(this.$route.path + "/confirm?id=" + text.id);
    },

    callback(text) {
      this.$router.push(this.$route.path + "/edit?id=" + text.id);
    },

    seeDetail(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    }
  }
};
</script>